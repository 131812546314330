import {createTheme} from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: "Clarika Geometric, Gelasio, Open Sans, Asap, Gelasio, Roboto Flex, Inter"
    },
    palette: {
        primary: {main: "#ffffff"},
        secondary: {main: "#ffffff"},
        action: {active: "#ffffff", focus: "#ffffff", hover: "#ffffff", selected: "#ffffff"},
        divider: "#ffffff"
    },
    shape: {borderRadius: 32}
});

export const THEMES = {theme};