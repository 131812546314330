import {Helmet} from "react-helmet-async";
import Opportunity from "../components/Opportunity";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Autocomplete, Box, Button, Container, Divider, Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import {South} from "@mui/icons-material";
import hexagonDownAnimation from "../animations/hex-arrange-in-v5-down.json";
import hexagonUpAnimation from "../animations/hex-arrange-in-v3-up.json";
import Lottie from "lottie-react";
import {AnimatePresence, motion} from "framer-motion";


const container = {
    animate: {
        opacity: 1,
        transition: {
            duration: 0.3,
            staggerChildren: 0.1,
            when: "beforeChildren",
            type: "spring",
            bounce: 1,
            stiffness: 150
        }
    },
    initial: {
        opacity: 0,
        transition: {
            duration: 0.1
        }
    }
}
const item = {
    animate: {
        opacity: 1
    },
    initial: {
        opacity: 0,
        transition: {
            duration: 0.1
        }}
}

const gridContainer = {
    initial: {
        opacity: 0,
        transition: {
            duration: 0.1
        }
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.3,
            staggerChildren: 0.1,
            when: "beforeChildren",
            type: "spring",
            bounce: 1,
            stiffness: 150
        }
    }
};

const gridItem = {
    initial: {
        opacity: 0,
        transition: {
            duration: 0.1
        }
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.3,
            staggerChildren: 0.1,
            when: "beforeChildren",
            type: "spring",
            bounce: 1,
            stiffness: 150
        }
    }
}


const HiringPage = ({opportunities, callback, callback2}) => {
    useEffect(() => {
        callback(false);
        callback2(false);
        // eslint-disable-next-lin react-hooks/exhaustive-deps
    }, [callback, callback2]);

    const [query, setQuery] = useState("");
    const [hasChanged, setHasChanged] = useState(false);
    const [companiesOpen, setCompaniesOpen] = useState(false);
    const [teamsOpen, setTeamsOpen] = useState(false);
    const [rolesOpen, setRolesOpen] = useState(false);
    const [locationsOpen, setLocationsOpen] = useState(false);
    const [filteredOpportunities, setFilteredOpportunities] = useState(opportunities);
    const gridRef = React.createRef();

    const [roles, setRoles] = useState([]);
    const [locations, setLocations] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [teams, setTeams] = useState([]);

    const [selectedRole, setSelectedRole] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedTeam, setSelectedTeam] = useState("");

    const scrollContainerRef = useRef();
    
    const eventListener = (e) => {
        let newScrollTop = scrollContainerRef.current.scrollTop + e.deltaY;
        if (scrollContainerRef.current && scrollContainerRef.current) {
            if (newScrollTop < 0) {
                newScrollTop = 0;
            }
            scrollContainerRef.current.scroll({ top: newScrollTop, behaviour: 'smooth' });
        }
    };

    useEffect(() => {
        window.addEventListener('wheel', eventListener);
        scrollContainerRef.current.addEventListener('wheel', (e) => {
            e.preventDefault();
        });
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.removeProperty('overflow');
            window.removeEventListener('wheel', eventListener);
        };
    },[])

    useEffect(() => {
      if (companiesOpen || rolesOpen || locationsOpen || teamsOpen) {
        window.removeEventListener('wheel', eventListener);
      } else {
        window.addEventListener('wheel', eventListener);
      }

      return () => window.removeEventListener('wheel', eventListener);
    }, [companiesOpen, rolesOpen, locationsOpen, teamsOpen])

    const handleFilterBySearchQuery = event => {
        setQuery(event.target.value);
        if (event.target.value === "") {
            setFilteredOpportunities(opportunities);
        } else {
            setFilteredOpportunities(filteredOpportunities.filter(opportunity => {
                return opportunity.role.toLowerCase().includes(event.target.value.toLowerCase()) || opportunity.description.toLowerCase().includes(event.target.value.toLowerCase());
            }))
        }
    };

    const filterByRole = (event, role) => {
        setHasChanged(!hasChanged);
        setSelectedRole(role);
        if (role === "") {
            setFilteredOpportunities(opportunities);
            return;
        }
        setFilteredOpportunities(filteredOpportunities.filter(opportunity => opportunity.role.toLowerCase() === role.toLowerCase()));
    };

    const filterByCompany = (event, company) => {
        setHasChanged(!hasChanged);
        setSelectedCompany(company);
        if (company === "") {
            setFilteredOpportunities(opportunities);
            return;
        }
        setFilteredOpportunities(filteredOpportunities.filter(opportunity => opportunity.company.name.toLowerCase() === company.toLowerCase()));
    };

    const filterByTeam = (event, team) => {
        setHasChanged(!hasChanged);
        setSelectedTeam(team);
        if (team === "") {
            setFilteredOpportunities(opportunities);
            return;
        }
        setFilteredOpportunities(filteredOpportunities.filter(opportunity => (opportunity.team || "").toLowerCase() === team.toLowerCase()));
    };

    const filterByLocation = (event, location) => {
        setHasChanged(!hasChanged);
        setSelectedLocation(location);
        if (location === "") {
            setFilteredOpportunities(opportunities);
            return;
        }
        setFilteredOpportunities(filteredOpportunities.filter(opportunity => opportunity.location.toLowerCase() === location.toLowerCase()));
    }

    const getRoles = useCallback(() => {
        if (opportunities) {
            const uniqueRoles = [];
            opportunities.forEach(opportunity => {
                if (!uniqueRoles.includes(opportunity.role)) {
                    uniqueRoles.push(opportunity.role)
                }
            });
            setRoles(uniqueRoles);
        }
    }, [opportunities]);


    const getLocations = useCallback(() => {
        if (opportunities) {
            const uniqueLocations = [];
            opportunities.forEach(opportunity => {
                if (!uniqueLocations.includes(opportunity.location)) {
                    uniqueLocations.push(opportunity.location)
                }
            });
            setLocations(uniqueLocations);
        }
    }, [opportunities]);


    const getCompanies = useCallback(() => {
        if (opportunities) {
            const uniqueCompanies = [];
            opportunities.forEach(opportunity => {
                if (!uniqueCompanies.includes(opportunity.company.name)) {
                    uniqueCompanies.push(opportunity.company.name)
                }
            });
            setCompanies(uniqueCompanies);
        }
    }, [opportunities]);

    const getTeams = useCallback(() => {
        if (opportunities) {
            const uniqueTeams = [];
            opportunities.forEach(opportunity => {
                if (!uniqueTeams.includes(opportunity.team)) {
                    uniqueTeams.push(opportunity.team)
                }
            });
            setTeams(uniqueTeams.filter(x => x));
        }
    }, [opportunities]);

    useEffect(() => {
        getRoles();
    }, [getRoles]);
    useEffect(() => {
        getLocations();
    }, [getLocations]);
    useEffect(() => {
        getCompanies();
    }, [getCompanies]);
    useEffect(() => {
        getTeams();
    }, [getTeams]);


    useEffect(() => {
        if (opportunities) {
            setFilteredOpportunities(opportunities);
        }
    }, [opportunities]);

    const hexagonDown = useRef();
    const hexagonUp = useRef();

    useEffect(() => {
        hexagonDown.current.playSegments([0, 50], true);
        hexagonUp.current.playSegments([0, 50], true);
    }, []);

    const handleFilterReset = () => {
        setHasChanged(!hasChanged);
        setSelectedLocation("");
        setSelectedRole("");
        setSelectedCompany("");
        setSelectedTeam("");
    }

    const handleCompanyClose = () => {
        setCompaniesOpen(false);
    }
    
    const handleTeamClose = () => {
        setTeamsOpen(false);
    }

    const handleCompanySelected = company => {
        setSelectedCompany(company);
        setCompaniesOpen(false);
    }

    const handleTeamSelected = company => {
        setSelectedTeam(company);
        setTeamsOpen(false);
    }

    const handleRoleClose = () => {
        setRolesOpen(false);
    }

    const handleRoleSelected = role => {
        setSelectedRole(role);
        setRolesOpen(false);
    }
    const handleLocationClose = () => {
        setLocationsOpen(value => !value);
    }

    const handleLocationSelected = location => {
        setSelectedLocation(location);
        setLocationsOpen(value => !value);
    }

    const handleCompanyRender = (props, option) => {
        return (
            <Box
                key={option}
                onClick={() => handleCompanySelected(option)}
                sx={{padding: 1, borderRadius: 8}}
                variants={gridContainer}
                initial="initial"
                animate="animate">
                <Typography variant="body2" sx={{color: "white", cursor: "pointer"}}>{option}</Typography>
            </Box>
        )
    }

    const handleTeamRender = (props, option) => {
        return (
            <Box
                key={option}
                onClick={() => handleTeamSelected(option)}
                sx={{padding: 1, borderRadius: 8}}
                variants={gridContainer}
                initial="initial"
                animate="animate">
                <Typography variant="body2" sx={{color: "white", cursor: "pointer"}}>{option}</Typography>
            </Box>
        )
    }

    const handleRoleRender = (props, option) => {
        return (
            <Box
                key={option}
                onClick={() => handleRoleSelected(option)}
                sx={{padding: 1, borderRadius: 8}}
                variants={gridContainer}
                initial="initial"
                animate="animate">
                <Typography variant="body2" sx={{color: "white", cursor: "pointer"}}>{option}</Typography>
            </Box>
        )
    }

    const handleLocationRender = (props, option) => {
        return (
            <Box
                key={option}
                onClick={() => handleLocationSelected(option)}
                sx={{padding: 1, borderRadius: 8}}
                variants={gridContainer}
                initial="initial"
                animate="animate">
                <Typography variant="body2" sx={{color: "white", cursor: "pointer"}}>{option}</Typography>
            </Box>
        )
    }

    return (
        <Box>
            <Helmet prioritizeSeoTags={true}>
                <title data-rh="true"></title>
            </Helmet>
            <div className="fixed top-0 min-h-screen left-0 w-screen z-0">
                <Lottie
                    lottieRef={hexagonDown}
                    className="absolute scale-50 lg:scale-100"
                    style={{left: "-300px", width: "700px", top: "-150px"}}
                    animationData={hexagonDownAnimation}
                    autoplay={false}
                    loop={false}
                />
                <Lottie
                    lottieRef={hexagonUp}
                    className="absolute scale-50 lg:scale-100"
                    style={{right: "-300px", width: "600px", bottom: "-300px"}}
                    animationData={hexagonUpAnimation}
                    autoplay={false}
                    loop={false}
                />
            </div>

            <div ref={scrollContainerRef} className="h-screen overflow-scroll no-scroll pb-56 lg:pb-36">
                <Container maxWidth={false}>
                    <Box
                        variants={container}
                        whileInView="animate"
                        initial="initial"
                        component={motion.div}
                        viewport={{once: true}}
                        direction="column"
                        spacing={4}
                        sx={{mb: 4}}>
                        <Container maxWidth="lg">
                            <Typography
                                className='clarikageo'
                                component={motion.h4}
                                variants={item}
                                viewport={{once: true}}
                                align="center"
                                variant="h4"
                                sx={{color: "#ffffff", mb: 4, mt: 12}}>
                                Opportunities in the Geometry Portfolio
                            </Typography>
                        
                            <Box sx={{mb: 4}} variants={item} component={motion.div}>
                                <Divider variant="fullWidth" light={true} sx={{mb: 1}}/>
                                <Grid
                                    columns={10}
                                    component={motion.div}
                                    variants={gridContainer}
                                    whileInView="animate"
                                    initial="initiate"
                                    viewport={{once: true}}
                                    container={true}
                                    spacing={{ xs: 2, md: 0 }}
                                    alignItems="center"
                                    justifyContent="space-between">
                                    <Grid
                                        component={motion.div}
                                        variants={gridItem}
                                        viewport={{once: true}}
                                        item={true} xs={12} md="auto">
                                        <Typography variant="body1" sx={{color: "#ffffff"}}>Filter</Typography>
                                    </Grid>
                                    <Grid
                                        component={motion.div}
                                          variants={gridItem}
                                          viewport={{once: true}}
                                          item={true}
                                          xs={12}
                                          md={2} lg={2}>
                                        <Autocomplete
                                            elevation={1}
                                            popupIcon={<South sx={{fontSize: 12}}/>}
                                            noOptionsText="No companies available"
                                            autoComplete={true}
                                            open={companiesOpen}
                                            onOpen={() => setCompaniesOpen(true)}
                                            onClose={handleCompanyClose}
                                            autoHighlight={true}
                                            autoSelect={false}
                                            renderOption={handleCompanyRender}
                                            ListboxComponent={props => {
                                                return (
                                                    <Stack
                                                        {...props}
                                                        direction="column"
                                                        component={motion.div}
                                                        variants={gridContainer}
                                                        initial="initial"
                                                        whileInView="animate"
                                                    />
                                                )
                                            }}
                                            PaperComponent={props => {
                                                return (
                                                    <Paper
                                                        key="companyDropdown"
                                                        animate={{
                                                            opacity: 1,
                                                            y: 0,
                                                            transition: {
                                                                duration: 0.3,
                                                            }
                                                        }}
                                                        initial={{
                                                            opacity: 0,
                                                            y: '1vh',
                                                        }}
                                                        exit={{
                                                            y: 50,
                                                            opacity: 0,
                                                            transition: {
                                                                duration: 0.5,
                                                                bounce: 0.3,
                                                                stiffness: 110,
                                                                type: "spring"
                                                            }
                                                        }}
                                                        component={motion.div}
                                                        sx={{backgroundColor: "rgba(97,218,251,0.85)", backdropFilter: "blur(40px)", borderRadius: 0.5, mt: 2}}
                                                        elevation={0}
                                                        {...props}
                                                    />
                                                )
                                            }}
                                            value={selectedCompany}
                                            onInputChange={filterByCompany}
                                            renderInput={params => {
                                                return (
                                                    <Box
                                                        initial={{
                                                            opacity: 0
                                                        }}
                                                        animate={{
                                                            opacity: 1,
                                                            transition: {
                                                                duration: 0.3,
                                                                type: "spring",
                                                                bounce: 1,
                                                                stiffness: 250
                                                            }
                                                        }}
                                                        component={motion.div}>
                                                        <TextField
                                                            sx={{
                                                                border: "none",
                                                                "& .MuiInputLabel-root": {color: "white"},
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {
                                                                        border: '1px solid rgba(255, 255, 255, 0.2)',
                                                                    }
                                                                },
                                                                "& .MuiOutlinedInput-root:hover": {
                                                                    "& > fieldset": {
                                                                        borderColor: "rgba(255,255,255,0.05)"
                                                                    }
                                                                },
                                                                input: {color: "white"},
                                                                label: {color: "white"}
                                                            }}
                                                            color="primary"
                                                            placeholder="Company"
                                                            fullWidth={true}
                                                            {...params}
                                                            label="Company"
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </Box>
                                                )
                                            }} options={companies}
                                        />
                                    </Grid>
                                    <Grid
                                        component={motion.div}
                                        variants={gridItem}
                                        item={true} xs={12} md={2} lg={2}>
                                        <Autocomplete
                                            popupIcon={<South sx={{fontSize: 12}}/>}
                                            noOptionsText="No roles available"
                                            open={rolesOpen}
                                            onOpen={() => setRolesOpen(true)}
                                            onClose={handleRoleClose}
                                            autoHighlight={true}
                                            autoSelect={false}
                                            renderOption={handleRoleRender}
                                            ListboxComponent={props => {
                                                return (
                                                    <Stack
                                                        {...props}
                                                        direction="column"
                                                        component={motion.div}
                                                        variants={gridContainer}
                                                        initial="initial"
                                                        whileInView="animate"
                                                    />
                                                )
                                            }}
                                            PaperComponent={props => {
                                                return (
                                                    <AnimatePresence>
                                                        {rolesOpen && (
                                                            <motion.div
                                                                key="roleDropdown"
                                                                animate={{
                                                                    opacity: 1,
                                                                    y: 0,
                                                                    transition: {
                                                                        duration: 0.3,
                                                                    }
                                                                }}
                                                                initial={{
                                                                    opacity: 0,
                                                                    y: '1vh',
                                                                }}
                                                                exit={{
                                                                    x: 50,
                                                                    opacity: 0,
                                                                    transition: {
                                                                        duration: 0.5,
                                                                        bounce: 0.3,
                                                                        stiffness: 110,
                                                                        type: "spring"
                                                                    }
                                                                }}>
                                                                <Paper
                                                                    component={motion.div}
                                                                    sx={{backgroundColor: "rgba(97,218,251,0.85)", backdropFilter: "blur(36px)", borderRadius: 0.5, mt: 2}}
                                                                    elevation={0}
                                                                    {...props}
                                                                />
                                                            </motion.div>
                                                        )}
                                                    </AnimatePresence>
                                                )
                                            }}
                                            value={selectedRole}
                                            onInputChange={filterByRole}
                                            renderInput={params => {
                                                return (
                                                    <TextField
                                                        sx={{
                                                            border: "none",
                                                            "& .MuiInputLabel-root": {color: "white"},
                                                            "& .MuiOutlinedInput-root": {
                                                                "& > fieldset": {
                                                                    border: '1px solid rgba(255, 255, 255, 0.2)',
                                                                }
                                                            },
                                                            "& .MuiOutlinedInput-root:hover": {
                                                                "& > fieldset": {
                                                                    borderColor: "rgba(255,255,255,0.05)"
                                                                }
                                                            },
                                                            input: {color: "white"},
                                                            label: {color: "white"}
                                                        }}
                                                        color="primary"
                                                        placeholder="Role"
                                                        fullWidth={true}
                                                        {...params}
                                                        label="Role"
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )
                                            }} options={roles}
                                        />
                                    </Grid>
                                    <Grid
                                        component={motion.div}
                                        variants={gridItem}
                                        item={true}
                                        xs={12} md={2} lg={2}>
                                        <Autocomplete
                                            popupIcon={<South sx={{fontSize: 12, color: "#ffffff"}}/>}
                                            noOptionsText="No locations available"
                                            open={locationsOpen}
                                            onOpen={() => setLocationsOpen(true)}
                                            onClose={handleLocationClose}
                                            autoHighlight={true}
                                            autoSelect={false}
                                            renderOption={handleLocationRender}
                                            ListboxComponent={props => {
                                                return (
                                                    <Stack
                                                        {...props}
                                                        direction="column"
                                                        component={motion.div}
                                                        variants={gridContainer}
                                                        initial="initial"
                                                        animate="animate"
                                                    />
                                                )
                                            }}
                                            PaperComponent={props => {
                                                return (
                                                    <AnimatePresence>
                                                        {locationsOpen && (
                                                            <motion.div
                                                                key="locationDropdown"
                                                                animate={{
                                                                    opacity: 1,
                                                                    y: 0,
                                                                    transition: {
                                                                        duration: 0.3,
                                                                    }
                                                                }}
                                                                initial={{
                                                                    opacity: 0,
                                                                    y: '1vh',
                                                                }}
                                                                exit={{
                                                                    x: 50,
                                                                    opacity: 0,
                                                                    transition: {
                                                                        duration: 0.5,
                                                                        bounce: 0.3,
                                                                        stiffness: 110,
                                                                        type: "spring"
                                                                    }
                                                                }}>
                                                                <Paper
                                                                    component={motion.div}
                                                                    sx={{backgroundColor: "rgba(97,218,251,0.85)", backdropFilter: "blur(40px)", borderRadius: 0.5, mt: 2}}
                                                                    elevation={0}
                                                                    {...props}
                                                                />
                                                            </motion.div>
                                                        )}
                                                    </AnimatePresence>
                                                )
                                            }}
                                            value={selectedLocation}
                                            onInputChange={filterByLocation}
                                            renderInput={params => {
                                                return (
                                                    <TextField
                                                        sx={{
                                                            border: "none",
                                                            "& .MuiInputLabel-root": {color: "white"},
                                                            "& .MuiOutlinedInput-root": {
                                                                "& > fieldset": {
                                                                    border: '1px solid rgba(255, 255, 255, 0.2)',
                                                                }
                                                            },
                                                            "& .MuiOutlinedInput-root:hover": {
                                                                "& > fieldset": {
                                                                    borderColor: "rgba(255,255,255,0.05)"
                                                                }
                                                            },
                                                            input: {color: "white"},
                                                            label: {color: "white"}
                                                        }}
                                                        color="primary"
                                                        label="Location"
                                                        placeholder="Location"
                                                        fullWidth={true}
                                                        {...params}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                )
                                            }} options={locations}
                                        />
                                    </Grid>
                                    <Grid
                                        component={motion.div}
                                          variants={gridItem}
                                          viewport={{once: true}}
                                          item={true}
                                          xs={12}
                                          md={2} lg={2}>
                                        <Autocomplete
                                            elevation={1}
                                            popupIcon={<South sx={{fontSize: 12}}/>}
                                            noOptionsText="No teams available"
                                            autoComplete={true}
                                            open={teamsOpen}
                                            onOpen={() => setTeamsOpen(true)}
                                            onClose={handleTeamClose}
                                            autoHighlight={true}
                                            autoSelect={false}
                                            renderOption={handleTeamRender}
                                            ListboxComponent={props => {
                                                return (
                                                    <Stack
                                                        {...props}
                                                        direction="column"
                                                        component={motion.div}
                                                        variants={gridContainer}
                                                        initial="initial"
                                                        whileInView="animate"
                                                    />
                                                )
                                            }}
                                            PaperComponent={props => {
                                                return (
                                                    <Paper
                                                        key="teamDropdown"
                                                        animate={{
                                                            opacity: 1,
                                                            y: 0,
                                                            transition: {
                                                                duration: 0.3,
                                                            }
                                                        }}
                                                        initial={{
                                                            opacity: 0,
                                                            y: '1vh',
                                                        }}
                                                        exit={{
                                                            y: 50,
                                                            opacity: 0,
                                                            transition: {
                                                                duration: 0.5,
                                                                bounce: 0.3,
                                                                stiffness: 110,
                                                                type: "spring"
                                                            }
                                                        }}
                                                        component={motion.div}
                                                        sx={{backgroundColor: "rgba(97,218,251,0.85)", backdropFilter: "blur(40px)", borderRadius: 0.5, mt: 2}}
                                                        elevation={0}
                                                        {...props}
                                                    />
                                                )
                                            }}
                                            value={selectedTeam}
                                            onInputChange={filterByTeam}
                                            renderInput={params => {
                                                return (
                                                    <Box
                                                        initial={{
                                                            opacity: 0
                                                        }}
                                                        animate={{
                                                            opacity: 1,
                                                            transition: {
                                                                duration: 0.3,
                                                                type: "spring",
                                                                bounce: 1,
                                                                stiffness: 250
                                                            }
                                                        }}
                                                        component={motion.div}>
                                                        <TextField
                                                            sx={{
                                                                border: "none",
                                                                "& .MuiInputLabel-root": {color: "white"},
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {
                                                                        border: '1px solid rgba(255, 255, 255, 0.2)',
                                                                    }
                                                                },
                                                                "& .MuiOutlinedInput-root:hover": {
                                                                    "& > fieldset": {
                                                                        borderColor: "rgba(255,255,255,0.05)"
                                                                    }
                                                                },
                                                                input: {color: "white"},
                                                                label: {color: "white"}
                                                            }}
                                                            color="primary"
                                                            placeholder="Team"
                                                            fullWidth={true}
                                                            {...params}
                                                            label="Team"
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </Box>
                                                )
                                            }} options={teams}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12} md="auto">
                                        <Button
                                            onClick={handleFilterReset}
                                            sx={{textTransform: "capitalize", color: "white"}}
                                            size="small"
                                            variant="text">
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth" light={true} sx={{mt: 1}}/>
                            </Box>
                        
                            <Box viewport={{once: true}} sx={{mb: 12}} variants={item} component={motion.div}>
                                <Grid container={true}>
                                    <Grid item={true} xs={12} md={4}>
                                        <TextField
                                            size="small"
                                            label="Search"
                                            variant="standard"
                                            color="primary"
                                            type="text"
                                            fullWidth={true}
                                            value={query}
                                            onChange={handleFilterBySearchQuery}
                                            placeholder="Search"
                                            sx={{
                                                border: "none",
                                                input: {color: "white"},
                                                label: {color: "white"},
                                                '& .MuiInput-underline:before': {borderBottomColor: 'rgba(255, 255, 255, 0.5)'},
                                                '& .MuiInput-underline:after': {borderBottomColor: 'white'},
                                                "& .MuiStandardInput-root:hover": {
                                                    borderBottomColor: 'white',
                                                    "& > fieldset": {
                                                        borderColor: "rgba(255,255,255,0.3)"
                                                    }
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        
                            <Box viewport={{once: true}} variants={item} component={motion.div}>
                                {filteredOpportunities?.length === 0 ? (
                                    <Box
                                        variants={item}
                                        sx={{
                                            minHeight: "30vh",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "rgba(255, 255, 255, 0.17)",
                                            height: "100%",
                                            borderRadius: 1,
                                            borderColor: "rgba(255, 255, 255, 0.3)",
                                            borderWidth: 1,
                                            borderStyle: "solid"
                                        }}>
                                        <Typography
                                            sx={{color: "#ffffff"}} variant="h6">
                                            No opportunities available
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Grid
                                        ref={gridRef}
                                        component={motion.div}
                                        whileInView="animate"
                                        initial="initial"
                                        variants={gridContainer}
                                        container={true}
                                        spacing={4}>
                                        <AnimatePresence
                                            initial={true}
                                            mode="popLayout"
                                            presenceAffectsLayout={true}>
                                            {filteredOpportunities?.map((opportunity, index) => {
                                                return (
                                                    <Grid
                                                        component={motion.div}
                                                        variants={gridItem}
                                                        xs={12}
                                                        md={6}
                                                        item={true}
                                                        exit="exit"
                                                        key={index}>
                                                        <Opportunity opportunity={opportunity}/>
                                                    </Grid>
                                                )
                                            })}
                                        </AnimatePresence>
                                    </Grid>
                                )}
                            </Box>
                        </Container>
                    </Box>
                </Container>
            </div>
        </Box>
    )
}

export default HiringPage;