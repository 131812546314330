import { Helmet } from "react-helmet-async";
import { truncateString } from "../helpers/truncateString.js";

export const META_DESCRIPTION = "Empowering protocols using cryptography"
export const META_KEYWORDS = "research, web3, cryptography, defi, startup, scalability, zk, zero-knowledge, snark, starks, trusted setup, trust"
export const META_BASE = "https://geometry.dev"
export const META_LOGO = `${META_BASE}/static/media/Geometry%20Logo%20only%20hexagon%20gradient.6475c3e9cbb472b9afee.png`

export const generateMetaSection = (title, image, description, keywords) => (
            <Helmet prioritizeSeoTags>
                <title data-rh="true">{title} - Geometry Research</title>
                <meta property="og:title" content={`${title} - Geometry Research`} />
                <meta property="og:image" content={image ? image: META_LOGO} />
                <meta property="og:type" content="website" />
                <meta name="description" content={description ? description : truncateString(META_DESCRIPTION, 152)} data-rh="true" />
                <meta name="keywords" content={META_KEYWORDS} data-rh="true" />
                <meta property="twitter:card" content="summary" data-rh="true"/>
                <meta property="twitter:creator" content="@__geometrydev__" data-rh="true"/>
            </Helmet>
);