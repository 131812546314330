import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { truncateString } from "../helpers/truncateString.js";
import Lottie from "lottie-react";
import hexagonUpAnimation from "../animations/hex-arrange-in-v1-up.json"
import hexagonDownAnimation from "../animations/hex-arrange-in-v2-down.json"
import { generateMetaSection } from "../helpers/meta.js";

export default function ContactPage({ callback, callback2, generalEmailAddress, investmentsEmailAddress, locations, socials, copyright, metaTitle, metaDescription, metaKeywords }) {

    const hexagonDown = useRef();
    const hexagonUp = useRef();

    useEffect(() => {
        callback(false);
        callback2(false);
        hexagonDown.current.playSegments([0, 50], true);
        hexagonUp.current.playSegments([0, 50], true);
    }, []);


    return (
        <>
            {generateMetaSection("Contact")}
            <div className="fixed top-0 h-screen left-0 w-screen z-0">
                <Lottie lottieRef={hexagonDown} className="absolute scale-50 lg:scale-100" style={{ left: "-300px", width: "600px", top: "-200px" }} animationData={hexagonDownAnimation} autoplay={false} loop={false} />
                <Lottie lottieRef={hexagonUp} className="absolute scale-50 lg:scale-100" style={{ right: "-300px", width: "600px", bottom: "-300px" }} animationData={hexagonUpAnimation} autoplay={false} loop={false} />
            </div>
            <div id="contact-page-container" className="absolute w-full h-full flex flex-col fade-in">
                <div className="grow flex flex-col justify-around items-center z-20">
                    <div>
                        <div className="flex flex-col items-center">
                            <button onClick={() => window.location = `mailto:${generalEmailAddress}`} className="order-2 mb-8 lg:mb-8 font-clarikageo font-light text-white hover:text-cyan transition-colors duration-500 text-2xl lg:text-2xl text-center">{generalEmailAddress}</button>
                        </div>
                    </div>
                    <div className="order-5 mb-2 items-end flex space-x-8">
                        {socials &&
                            <>
                                {socials.twitterHandle &&
                                    <a href={socials.twitterHandle} target="_blank" rel="noreferrer noopener" className="text-white hover:text-cyan transition-colors duration-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                        </svg>
                                    </a>
                                }
                                {socials.githubHandle &&
                                    <a href={socials.githubHandle} target="_blank" rel="noreferrer noopener" className="text-white hover:text-cyan transition-colors duration-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                                        </svg>
                                    </a>
                                }
                            </>
                        }

                    </div>
                </div>
                {/*
                <div className="grow-0 z-20">
                    <p className="mb-6 text-white font-clarikageo text-sm lg:text-base select-none text-center">
                        <Link className="hover:text-cyan transition-colors duration-500 whitespace-nowrap" to="/page/terms-of-use">Terms of Use</Link>
                        <span className="mx-1">|</span>
                        <Link className="hover:text-cyan transition-colors duration-500 whitespace-nowrap" to="/page/privacy-policy">Privacy Policy</Link>
                    </p>
                    <p className="text-white font-clarikageo text-sm lg:text-base text-center pb-16 select-none">{copyright}</p>
                </div>
                    */}
            </div>
        </>
    );
}

