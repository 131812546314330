// React
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// Sanity
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import Menu from "./Menu.js";
import { FullScreenContext, FullScreenProvider } from './FullScreenProvider';

// Lottie
import Lottie from "lottie-react";
// Lottie animations
import geometryLogoAnimation from "../animations/geometry-logo-animation.json";
import geometryTextInAnimation from "../animations/geometry-text-in.json";
import hexArrangeInV1Up from "../animations/hex-arrange-in-v1-up.json"
import hexArrangeInV2Down from "../animations/hex-arrange-in-v2-down.json"
import hexArrangeInV3Up from "../animations/hex-arrange-in-v3-up.json"
import hexArrangeInV4Up from "../animations/hex-arrange-in-v4-up.json"
import hexArrangeInV5Down from "../animations/hex-arrange-in-v5-down.json"

import WhiteLogoOnlyText from "../assets/images/Geometry Logo only text plain.svg";
import WhiteLogoOnlyTextGradient from "../assets/images/Geometry Logo only text gradient plain.svg";
import GradientLogo from "../assets/images/Geometry Logo only hexagon plain.svg";
import GradientLogoGradient from "../assets/images/Geometry Logo only hexagon gradient plain.svg";
import usePageTracking from '../hooks/usePageTracking.js';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {

  return builder.image(source);
}

const DefaultIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="flex-none opacity-20 group-hover:opacity-100"
    width="45.123"
    height="52.105"
    viewBox="0 0 54.514 62.948"
  >
    <path
      id="Subtraction_2"
      data-name="Subtraction 2"
      d="M-3069.743-1178.052h0L-3097-1193.79v-31.474l27.257-15.736,27.257,15.736v31.474l-27.257,15.737Zm-10.845-44.252a5.276,5.276,0,0,0-5.27,5.27v15.81a5.276,5.276,0,0,0,5.27,5.27h3.821l5.865,4.958a1.318,1.318,0,0,0,.85.312,1.321,1.321,0,0,0,.852-.312l5.872-4.958h3.821a5.276,5.276,0,0,0,5.271-5.27v-15.81a5.276,5.276,0,0,0-5.271-5.27Zm10.54,28.577h0l-5.015-4.237a2.639,2.639,0,0,0-1.7-.624h-3.821a2.638,2.638,0,0,1-2.635-2.634v-15.81a2.638,2.638,0,0,1,2.635-2.635h21.08a2.638,2.638,0,0,1,2.635,2.635v15.81a2.638,2.638,0,0,1-2.635,2.634h-3.821a2.642,2.642,0,0,0-1.7.624l-5.018,4.237Zm-6.588-11.449a1.319,1.319,0,0,0-1.318,1.318,1.319,1.319,0,0,0,1.318,1.318h13.176a1.319,1.319,0,0,0,1.317-1.318,1.319,1.319,0,0,0-1.317-1.318Zm0-5.27a1.319,1.319,0,0,0-1.318,1.318,1.319,1.319,0,0,0,1.318,1.318h13.176a1.319,1.319,0,0,0,1.317-1.318,1.319,1.319,0,0,0-1.317-1.318Zm0-5.27a1.319,1.319,0,0,0-1.318,1.317,1.319,1.319,0,0,0,1.318,1.318h6.588a1.319,1.319,0,0,0,1.318-1.318,1.319,1.319,0,0,0-1.318-1.317Z"
      transform="translate(3097 1241)"
      fill="#c1f7f5" />
  </svg>
);
const navItems = [
  {
    name: "about",
    url: "/about",
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-none opacity-20 group-hover:opacity-100"
        width="45.123"
        height="52.105"
        viewBox="0 0 54.514 62.948"
      >
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M-3069.743-1178.052h0L-3097-1193.79v-31.474l27.257-15.736,27.257,15.736v31.474l-27.257,15.737Zm-10.845-44.252a5.276,5.276,0,0,0-5.27,5.27v15.81a5.276,5.276,0,0,0,5.27,5.27h3.821l5.865,4.958a1.318,1.318,0,0,0,.85.312,1.321,1.321,0,0,0,.852-.312l5.872-4.958h3.821a5.276,5.276,0,0,0,5.271-5.27v-15.81a5.276,5.276,0,0,0-5.271-5.27Zm10.54,28.577h0l-5.015-4.237a2.639,2.639,0,0,0-1.7-.624h-3.821a2.638,2.638,0,0,1-2.635-2.634v-15.81a2.638,2.638,0,0,1,2.635-2.635h21.08a2.638,2.638,0,0,1,2.635,2.635v15.81a2.638,2.638,0,0,1-2.635,2.634h-3.821a2.642,2.642,0,0,0-1.7.624l-5.018,4.237Zm-6.588-11.449a1.319,1.319,0,0,0-1.318,1.318,1.319,1.319,0,0,0,1.318,1.318h13.176a1.319,1.319,0,0,0,1.317-1.318,1.319,1.319,0,0,0-1.317-1.318Zm0-5.27a1.319,1.319,0,0,0-1.318,1.318,1.319,1.319,0,0,0,1.318,1.318h13.176a1.319,1.319,0,0,0,1.317-1.318,1.319,1.319,0,0,0-1.317-1.318Zm0-5.27a1.319,1.319,0,0,0-1.318,1.317,1.319,1.319,0,0,0,1.318,1.318h6.588a1.319,1.319,0,0,0,1.318-1.318,1.319,1.319,0,0,0-1.318-1.317Z"
          transform="translate(3097 1241)"
          fill="#c1f7f5"
        />
      </svg>
    ),
  },
  {
    name: "Team",
    url: "/team",
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-none opacity-20 group-hover:opacity-100"
        width="45.123"
        height="52.105"
        viewBox="0 0 45.123 52.105"
      >
        <path
          id="Subtraction_3"
          data-name="Subtraction 3"
          d="M-3074.438-1188.9h0L-3097-1201.922v-26.052l22.562-13.026,22.561,13.026v26.052l-22.561,13.027Zm-6.087-35.753a4.361,4.361,0,0,0-4.349,4.349v12.175a4.361,4.361,0,0,0,4.349,4.349h9.878a4.35,4.35,0,0,0,3.062-1.273l2.309-2.311a4.35,4.35,0,0,0,1.274-3.074v-5.535a.87.87,0,0,0-.869-.869.871.871,0,0,0-.87.869v4.367h-3.479a2.612,2.612,0,0,0-2.609,2.609v3.479h-7.115a5.153,5.153,0,0,0,1.358-.979l12.622-12.622a3.24,3.24,0,0,0,0-4.569,3.291,3.291,0,0,0-2.285-.917,3.29,3.29,0,0,0-2.285.917l-12.622,12.622a5.135,5.135,0,0,0-.979,1.358V-1220.3a2.612,2.612,0,0,1,2.609-2.609h7.863a.871.871,0,0,0,.87-.87.871.871,0,0,0-.87-.869Zm10.436,19.066h0v-3.41a.87.87,0,0,1,.869-.869h3.414a2.623,2.623,0,0,1-.7,1.273l-2.314,2.312a2.58,2.58,0,0,1-1.272.7Zm-11.156-1.466h-.356v-.355a3.113,3.113,0,0,1,.909-2.2l12.622-12.622a1.185,1.185,0,0,1,.821-.328,1.189,1.189,0,0,1,.822.328,1.165,1.165,0,0,1,0,1.643l-12.622,12.622A3.113,3.113,0,0,1-3081.245-1207.049Z"
          transform="translate(3097 1241)"
          fill="#d2f9f8"
        />
      </svg>
    ),
  },
  {
    name: "contact",
    url: "/contact",
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="flex-none opacity-20 group-hover:opacity-100"
        width="45.123"
        height="52.105"
        viewBox="0 0 45.123 52.105"
      >
        <path
          id="Subtraction_8"
          data-name="Subtraction 8"
          d="M-3074.438-1188.9h0L-3097-1201.922v-26.052l22.562-13.026,22.561,13.026v26.052l-22.561,13.027Zm-6.994-18.069h0a2.511,2.511,0,0,0,1.08.252h2.663a.834.834,0,0,1,.594.246l1.444,1.444a4.2,4.2,0,0,0,2.96,1.238,4.237,4.237,0,0,0,1.349-.222,4.122,4.122,0,0,0,2.812-3.351l2.838-13.482a2.512,2.512,0,0,0-.666-2.381,2.508,2.508,0,0,0-1.774-.742,2.506,2.506,0,0,0-.58.068l-13.451,2.834a4.173,4.173,0,0,0-3.41,2.822,4.175,4.175,0,0,0,1.011,4.308l1.444,1.444a.837.837,0,0,1,.247.6v2.663a2.511,2.511,0,0,0,.252,1.08l-.006.006.021.022a2.534,2.534,0,0,0,1.145,1.14l.023.022,0-.006Zm8.753,1.494a2.509,2.509,0,0,1-1.782-.736l-1.446-1.445a2.515,2.515,0,0,0-1.774-.738h-2.324l12.634-12.63-2.812,13.374a2.508,2.508,0,0,1-1.694,2.044A2.52,2.52,0,0,1-3072.679-1205.471Zm-8.514-4.1v-2.316a2.521,2.521,0,0,0-.735-1.783l-1.446-1.444a2.48,2.48,0,0,1-.606-2.584,2.509,2.509,0,0,1,2.1-1.7l13.318-2.8-12.631,12.632Z"
          transform="translate(3097 1241)"
          fill="#d2f9f8"
        />
      </svg>
    ),
  },
];



export default function Layout({ callback, light, landing, children }) {
  usePageTracking();

  const [hoveredMenuItemIndex, setHoveredMenuItemIndex] = useState(-1);
  const [screenHeight, setScreenHeight] = useState('100vh');

  const { isFullScreen, setButtonVisible } = useContext(FullScreenContext);

  const NavItem = (navItem, index) => (
    <li className='relative' key={navItem.name} onMouseEnter={() => setHoveredMenuItemIndex(index)} onMouseLeave={() => setHoveredMenuItemIndex(-1)}>
      <Link
        to={navItem.url}
        className={`font-clarikageo font-demibold tracking-[4px] lg:tracking-[6px] xl:tracking-[8px] text-xs lg:text-base ${light ? "text-blue" : "text-white"} hover:text-cyan uppercase transition-colors duration-500`}>{navItem.name}</Link>

      {navItem.subMenu && <div className={`w-max min-w-full absolute pt-1 ${index !== hoveredMenuItemIndex ? 'opacity-0 scale-y-0 -translate-y-1/2' : 'opacity-100 scale-y-100 translate-y-0'} transition-all duration-300`}>
        <div className='rounded-md my-1'>
          {
            navItem.subMenu.map((item, subMenuIndex) => <Link
              to={item.url}
              key={subMenuIndex}
              className={`overflow-hidden rounded-md relative font-clarikageo font-demibold text-xs lg:text-base ${light ? "text-blue" : "text-white"} font-normal hover:text-cyan hover:opacity-97 transition-colors duration-300 z-10`}>
              {light && <div className='absolute inset-0 bg-white opacity-10'></div>}
              <div className={"px-2 py-0.5 " + (light ? "opacity-97 bg-white" : "")}>{item.name}</div>
            </Link>)
          }
        </div>
      </div>}
    </li>
  );

  const [data, setData] = useState(null);

  const hexBuildDown = useRef();
  const hexBuildDown2 = useRef();
  const hexBuildUp = useRef();
  const hexBuildUp2 = useRef();
  const hexBuildUp3 = useRef();

  const geometryTextIn = useRef();

  let backgroundStyle = {};
  let backgroundStyleInner = {};
  let overlayClasses = "bg-blue";

  const menuButton = useRef();
  const menu = useRef();
  const content = useRef();

  useEffect(() => {
    if (isFullScreen) {
      return;
    }

    /* TODO: lottie
    geometryTextIn.current.setDirection(-1);
    geometryTextIn.current.playSegments([50, 1]);
    */

    sanityClient.fetch(`{
            "globals": *[_type == "globals"] {
                background {
                    asset-> {
                        _id,
                        url,
                    },
                },
                backgroundInner{
                    asset-> {
                        _id,
                        url,
                    },
                },
                home {
                    text,
                },
                about {
                    title,
                    textOne,
                    textTwo,
                },
                notebook {
                    title,
                },
                notebookPlaceholder {
                    asset-> {
                        _id,
                        url,
                    },
                },
                portfolio {
                    title,
                },
                team {
                    title,
                },
                contact {
                    title,
                    generalEmailAddress,
                    investmentsEmailAddress,
                    socials {
                        twitterHandle,
                        linkedinHandle,
                        githubHandle,
                    },
                    "locations": locations[] {
                        title,
                    },
                    copyright,
                }
            },
            "firms": *[_type == "firm"] {
                title,
                slug,
                icon {
                    asset-> {
                        _id,
                        url,
                    },
                },
                hoverIcon {
                    asset-> {
                        _id,
                        url,
                    },
                },
                logo {
                    asset-> {
                        _id,
                        url,
                    },
                },
                description,
                url,
                category,
            },
            "teamMembers": *[_type == "teamMember"] {
                title,
                slug,
                image {
                    asset-> {
                        _id,
                        url,
                    },
                },
                position,
                description,
                socials {
                    twitterHandle,
                    linkedinHandle,
                    githubHandle,
                },
            },   
        }`).then((data) => setData(data)).catch(console.error);

    if (window.innerWidth < 1024) {
      setScreenHeight(`${window.innerHeight}`);
    } else {
      setScreenHeight('100vh');
    }


  }, [screenHeight]);

  if (data) {

    backgroundStyle["backgroundImage"] = `url(${urlFor(data.globals[0].background).width(1920).url()})`;
    backgroundStyleInner["backgroundImage"] = `url(${urlFor(data.globals[0].backgroundInner).width(1920).url()})`;
    overlayClasses = light ? "bg-white" : "transparent";
  }

  const onMenuClick = function () {

    if (menuButton.current.classList.contains("opened")) {

      menu.current.classList.add("hidden");
      content.current.classList.remove("hidden");
      if (window.location.pathname.includes('/page/') || window.location.pathname.includes('/notebook/')) callback(true);
      setButtonVisible(true);
    } else {

      content.current.classList.add("hidden");
      menu.current.classList.remove("hidden");
      callback(false);
      setButtonVisible(false);
    }

    menuButton.current.classList.toggle("opened");
    menuButton.current.setAttribute("aria-expanded", menuButton.current.classList.contains("opened"));
  }

  if (isFullScreen) {
    return <div className="w-screen h-full overflow-hidden relative mix-blend-screen" style={{ height: screenHeight }}>{children}</div>;
  }

  return (
    <div className="w-screen h-full overflow-hidden relative bg-blue mix-blend-screen"
      style={{ height: screenHeight }}>
      {/* Background image */}
      <div
        className={`absolute left-0 w-screen top-0 h-screen bg-movement transition-opacity duration-500 ${landing ? "opacity-0" : "opacity-100"}`}
        style={backgroundStyleInner}></div>
      <div
        className={`absolute left-0 w-screen top-0 h-screen bg-movement transition-opacity duration-500 ${landing ? "opacity-100" : "opacity-0"}`}
        style={backgroundStyle}></div>
      {/* Overlay */}
      <div
        className={`absolute left-0 w-screen top-0 h-screen transition-colors duration-1000 ${overlayClasses}`}></div>
      {/* Background animation */}
      <div className="absolute top-0 h-full left-0 w-full">
        <Lottie
          lottieRef={hexBuildDown}
          className="absolute"
          style={{ left: "-300px", width: "600px", top: "-200px" }}
          animationData={hexArrangeInV2Down}
          autoplay={false}
          loop={false}
        />
        <Lottie
          lottieRef={hexBuildDown2}
          className="absolute"
          style={{ left: "-300px", width: "700px", top: "-150px" }}
          animationData={hexArrangeInV5Down}
          autoplay={false}
          loop={false}
        />
        <Lottie
          lottieRef={hexBuildUp}
          className="absolute"
          style={{ right: "-300px", width: "600px", bottom: "-300px" }}
          animationData={hexArrangeInV1Up}
          autoplay={false}
          loop={false}
        />
        <Lottie
          lottieRef={hexBuildUp2}
          className="absolute"
          style={{ right: "-300px", width: "600px", bottom: "-300px" }}
          animationData={hexArrangeInV3Up}
          autoplay={false}
          loop={false}
        />
        <Lottie
          lottieRef={hexBuildUp3}
          className="absolute"
          style={{ right: "-200px", width: "700px", bottom: "-200px" }}
          animationData={hexArrangeInV4Up}
          autoplay={false}
          loop={false}
        />
      </div>
      <div className="px-8 lg:px-16 pt-10 lg:pt-20 absolute top-0 h-full left-0 w-full flex flex-col">
        {/* Nav */}
        <nav className="w-full h-[40px] lg:h-[30px] flex-none flex flex-row z-20 overflow-visible mb-6 lg:mb-0">
          <Link to="/" alt="Geometry" className="h-[30px] w-40 md:w-20 lg:w-40 relative md:mr-2 lg:mr-0">
            {light /* TODO: lottie */ ?
              <img src={WhiteLogoOnlyTextGradient}
                className={`absolute top-0 left-0 h-full w-auto scale-[2] translate-x-[-10px] transition-all duration-500`}
                style={{ transformOrigin: "center left" }} alt="" />
              :
              <img src={WhiteLogoOnlyText}
                className={`absolute top-0 left-0 h-full w-auto scale-[2] translate-x-[-10px] transition-all duration-500`}
                style={{ transformOrigin: "center left" }} alt="" />

            }
          </Link>
          <div className="h-full flex-1 flex justify-end items-center relative -top-2 lg:top-auto">
            <ul className="hidden md:flex md:space-x-4 lg:space-x-8">
              {navItems.map((navItem, index) => NavItem(navItem, index))}
            </ul>
            <button ref={menuButton} className="block md:hidden menu aspect-[1] h-full w-[40px] flex-none"
              aria-label="Main menu" onClick={onMenuClick}>
              <svg className="w-full h-full" viewBox="0 0 100 100">
                <path className={`line line1${light ? " line-2" : ""} transition-all duration-500`}
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                <path className={`line line2${light ? " line-2" : ""} transition-all duration-500`}
                  d="M 35,50 H 80" />
                <path className={`line line3${light ? " line-2" : ""} transition-all duration-500`}
                  d="M 50,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
              </svg>
            </button>
          </div>
        </nav>
        <article className="flex-1 relative container mx-auto">
          <span ref={menu} className="hidden">
            <Menu navItems={navItems} callback={onMenuClick} />
          </span>
          <span ref={content}>
            {children}
          </span>
        </article>
        {/* Icon */}
        <button
          className="hidden md:block absolute bottom-7 lg:bottom-14 h-10 right-8 lg:right-16 w-10 overflow-visible"
          disabled>
          {light ?
            <img
              src={GradientLogoGradient}
              className="h-full scale-[1.672697963]"
              alt=""
            />
            : <img
              src={GradientLogo}
              className="w-10 h-full scale-[1.672697963]"
              alt=""
            />
          }
        </button>
      </div>
    </div>
  );
}
