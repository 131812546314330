import React, {useEffect, useState, useRef, useContext} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {Link, useParams} from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import {Helmet} from "react-helmet-async";
import {truncateString} from "../helpers/truncateString.js";
import ReactMarkdown from '../helpers/markdown.js';
import {marked} from 'marked';

import Scroll from 'react-scroll';
import Latex from "react-latex";
import Loader from "../components/Loader";
import Lottie from "lottie-react";
import hexagonUpAnimation from "../animations/hex-arrange-in-v1-up-white.json"
import hexagonDownAnimation from "../animations/hex-arrange-in-v2-down-white.json"
import { FullScreenContext, FullScreenToggle } from './FullScreenProvider.js';
import { generateMetaSection } from "../helpers/meta.js";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
    return builder.image(source);
}

const Sanitize = function (string) {
    return string.replace(/[^a-zA-Z0-9,;\-.!? ]/g, '').replace(/\s/g, "-").toLowerCase();
}

const HeadingRenderer = (node, ...props) => {
    return React.createElement('h' + node.level, {id: Sanitize(node.children[0]), ...props}, node.children);
}

const ImageRenderer = (node, ...props) => {
    return <figure>
        <img src={node.src}/>
        {node.alt ? <figcaption class="articleCaption">{node.alt}</figcaption> : ''}
    </figure>
}

const TableRenderer = (node, ...props) => {
    return <div class="tableContainer">
        <table {...props}>
            {node.children}
        </table>
    </div>
}

export default function NotebookEntry({callback, placeholderImage}) {

    var SrollLink = Scroll.Link;
    var Element = Scroll.Element;

    const extractHeadings = (markdown, depth) => {
        const headings = [];
        const walkTokens = (token) => {
            if (token.type === 'heading' && token.depth == depth) {
                headings.push(token.text);
            }
        };

        marked.use({walkTokens});
        marked.parse(markdown);
        return headings;
    };

    const BlockRenderer = (props) => {
        const {style = 'normal'} = props.node

        if (style === 'h2') {
            return <Element name={Sanitize(props.children[0])}>
                {BlockContent.defaultSerializers.types.block(props)}
            </Element>
        }

        if (style === 'hr') {
            return <hr/>
        }

        return BlockContent.defaultSerializers.types.block(props);
    }

    const serializers = {
        types: {
            block: BlockRenderer,
            latex: (props) => (
                <span className="latex-wrapper no-scroll"><Latex>{`$${props.node.body}$`}</Latex></span>),
            image: (props) => (
                <figure>
                    <img src={urlFor(props.node.asset._ref)}/>
                    {props.node.caption ? <figcaption>{props.node.caption}</figcaption> : ''}
                </figure>
            )
        },
        marks: {
            link: ({mark, children}) => {
                const {blank, href} = mark
                return blank ?
                    <a href={href} target="_blank" rel="noopener">{children}</a>
                    : <a href={href}>{children}</a>
            },
            internalLink: ({mark, children}) => {
                const {slug = {}} = mark
                const href = `/notebook/${slug.current}`
                return <a href={href}>{children}</a>
            },
        }
    }

    const [data, setData] = useState(null);
    const {slug} = useParams();
    // const [meta, setMeta] = useState(null);

    const hexagonDown = useRef();
    const hexagonUp = useRef();
    const scrollContainerRef = useRef();

    const { isFullScreen } = useContext(FullScreenContext);

    useEffect(() => {
        const eventListener = (e) => {
            if (scrollContainerRef.current && scrollContainerRef.current.childBindings.domNode) {
                let newScrollTop = scrollContainerRef.current.childBindings.domNode.scrollTop + e.deltaY;
                if (newScrollTop < 0) {
                    newScrollTop = 0;
                }
                scrollContainerRef.current.childBindings.domNode.scroll({ top: newScrollTop, behaviour: 'smooth' });
            }
        };
        window.addEventListener('wheel', eventListener);
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.removeProperty('overflow');
            window.removeEventListener('wheel', eventListener);
        };
    },[])

    useEffect(() => {

        callback(true);
        hexagonDown.current.playSegments([0, 50], true);
        hexagonUp.current.playSegments([0, 50], true);

        sanityClient.fetch(`{
            "globals": *[_type == "globals"] {
                background {
                    asset-> {
                        _id,
                        url,
                    },
                },
            },
            "article": *[slug.current == $slug] {
                title,
                slug,
                metaTitle,
                metaDescription,
                metaKeywords,
                postDate,
                image {
                    asset-> {
                        _id,
                        url
                    },
                    caption
                },
                body[]{
                    ...,
                    markDefs[]{
                        ...,
                        _type == "internalLink" => {
                            "slug": @.reference->slug
                        }
                    }
                },
                bodymarkdown,
                usefulLinks
            },
        }`, {slug}).then((data) => {

            setData(data);

        }).catch(console.error);

    }, [slug]);

    return (
        <>
            {isFullScreen && <FullScreenToggle mountOnBody hideInDesktop />}
            {data &&

            generateMetaSection(
                data.article[0].metaTitle ? data.article[0].metaTitle : data.article[0].title, 
                data.article[0].image ? data.article[0].image.asset.url : placeholderImage.asset.url,
                data.article[0].metaDescription && truncateString(data.article[0].metaDescription),
                data.article[0].metaKeywords,
            )}

            <div className="relative w-full h-full overflow-visible">
                <div className="fixed top-0 h-screen left-0 w-screen z-0">
                    <Lottie
                        lottieRef={hexagonDown}
                        className="absolute scale-50 lg:scale-100"
                        style={{left: "-300px", width: "600px", top: "-200px"}}
                        animationData={hexagonDownAnimation}
                        autoplay={false}
                        loop={false}
                    />
                    <Lottie
                        lottieRef={hexagonUp}
                        className="absolute scale-50 lg:scale-100"
                        style={{right: "-200px", width: "700px", bottom: "-300px"}}
                        animationData={hexagonUpAnimation}
                        autoplay={false}
                        loop={false}
                    />
                </div>
                <div
                    className={`absolute w-full h-full left-0 top-0 overflow-hidden flex px-0 sm:pl-0 ${!isFullScreen ? 'sm:pr-10 xl:pr-16' : ''} pt-4`}>
                    {data && !isFullScreen ?
                        <>
                            <aside className="hidden lg:flex flex-col flex-none z-20 pb-10 lg:pb-20 w-52 fade-in">
                                <div className="mb-6 flex-col">
                                    {window.location.pathname.includes('/page/') ?
                                        <Link to="/contact"
                                              className="text-blue text-base flex items-center group hover:text-cyan transition-colors duration-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 fill="currentColor"
                                                 className="bi bi-chevron-compact-left text-blue group-hover:text-cyan transition-all duration-500"
                                                 viewBox="0 0 16 16">
                                                <path fillRule="evenodd"
                                                      d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
                                            </svg>
                                            Back
                                        </Link>
                                        :
                                        <Link
                                            to="/notebook"
                                            className="text-blue text-base flex items-center group hover:text-cyan font-opensans transition-colors duration-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 fill="currentColor"
                                                 className="bi bi-chevron-compact-left text-blue group-hover:text-cyan transition-all duration-500"
                                                 viewBox="0 0 16 16">
                                                <path fillRule="evenodd"
                                                      d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
                                            </svg>
                                            Back to notebook
                                        </Link>
                                    }
                                </div>
                                <h2 className="font-semibold font-opensans text-lg text-blue mb-6">{data.article[0].title}</h2>
                                {extractHeadings(data.article[0].bodymarkdown, 2).map((block, i) => (
                                    <SrollLink key={i} activeClass="list-active"
                                               className="cursor-pointer font-opensans font-light text-blue pl-4 text-base border-l-4 mb-2 border-transparent hover:lg:underline hover:text-cyan transition-colors duration-500"
                                               to={Sanitize(block)} spy={true} smooth={true} duration={250}
                                               containerId="containerElement">{block}</SrollLink>
                                ))}
                                <SrollLink activeClass="active"
                                           className="cursor-pointer font-opensans mt-6 text-blue text-base flex flex-col hover:text-cyan transition-colors duration-500"
                                           to="firstInsideContainer" spy={true} smooth={true} duration={250}
                                           containerId="containerElement">Back to top</SrollLink>
                                {data.article[0].usefulLinks ? (
                                    <div className="mt-auto flex-col">
                                        <h3 className="font-semibold font-opensans text-lg text-cyan mt-6 mb-2">Useful Links</h3>
                                        {data.article[0].usefulLinks.map((link, i) => (
                                            <a href={link.url}
                                               className="font-light font-opensans text-blue pl-8 text-base flex hover:text-cyan transition-colors duration-500"
                                               key={i} target="_blank" rel="noreferrer noopener">{link.title}</a>
                                        ))}
                                    </div>
                                ) : null}
                                <FullScreenToggle containerClasses={'relative inset-0 mt-4'}></FullScreenToggle>
                            </aside>
                            <div className="hidden lg:block w-0.5 h-full bg-cyan mx-10 xl:mx-16 z-20"></div>
                        </>
                        :
                        null
                    }
                    <Element
                        onWheel={(e) => e.stopPropagation()}
                        ref={scrollContainerRef}
                        name="test7"
                        className={`element h-100 no-scroll overflow-y-auto overflow-x-hidden ${isFullScreen && 'p-6 w-full py-12 xl:w-3/4 mx-auto'}`}
                        id="containerElement">
                        <article className="font-opensans body-content">
                            {data ?
                                <div className="fade-in">
                                    <Element name="firstInsideContainer">
                                        <h1 className="font-semibold text-blue">{data.article[0].title}</h1>
                                    </Element>
                                    {window.location.pathname.includes('/page/') ?
                                        ''
                                        : <small
                                            className="inline-block font-opensans font-light text-blue mb-3">
                                            {new Date(data.article[0].postDate).toLocaleString("en-za", {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}</small>}
                                    <ReactMarkdown
                                        children={data.article[0].bodymarkdown}
                                        components={{
                                            h2: HeadingRenderer,
                                            img: ImageRenderer,
                                            table: TableRenderer
                                        }}/>
                                    <div className="hidden lg:block w-full h-half-screen"></div>
                                </div>
                                :
                                <Loader/>
                            }
                        </article>
                    </Element>
                </div>
            </div>
        </>
    );
}
