import { Avatar, Box, Button, Card, CardContent, Grid, Link, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import ReactGA from "react-ga4";

const Opportunity = ({opportunity}) => {

    const {role, description, descriptionButtonText, company, link, email} = opportunity;

    const trackDescriptionBtnClick = useCallback(() => {
        ReactGA.event({
            category: "Opportunity",
            action: "Opportunity Open",
            label: `${role} - ${company.name}`,
            transport: "xhr",
        });
    }, [opportunity]);

    return (
        <Card
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            elevation={0}
            sx={{
                backgroundColor: "rgba(255, 255, 255, 0.05)",
                borderRadius: 1,
                borderColor: "rgba(255, 255, 255, 0.15)",
                borderWidth: 1,
                borderStyle: "solid",
                height: {xs: "100%"},
                backdropFilter: "blur(36.3611px)",
                '&:hover': {
                    backgroundColor: "rgba(255, 253, 250, 0.1)",
                    borderColor: "rgba(255, 255, 255, 0.2)",
                    backdropFilter: "blur(55px)",
                    transition: "all 300ms ease-out"
                }
            }}>
            <CardContent sx={{height: "100%"}}>
                <Stack
                    sx={{width: "100%", height: "100%"}}
                    direction="row"
                    spacing={1}
                    alignItems="flex-end"
                    justifyContent="space-between">
                    <Box sx={{flexBasis: {xs: "100%", lg: "85%"}, width: "100%", height: "100%"}}>
                        <Stack
                            sx={{width: "100%", height: "100%"}}
                            direction="column"
                            spacing={1}>
                            <Box display="flex" flex={1} justifyContent="center">
                                <Typography
                                    sx={{color: "white", textAlign: "center"}}
                                    variant="h6">
                                    {role}
                                </Typography>
                            </Box>
                            <Box sx={{width: "100%"}}>
                                <Stack
                                    alignItems="center"
                                    sx={{width: "100%"}}
                                    direction="row"
                                    spacing={2}
                                    key="applyButton">
                                    <Box sx={{width: {xs: "100%", lg: "100%"}}}>
                                        <Grid
                                            container={true}
                                            sx={{width: "100%", mt: 1}}
                                            spacing={2}>
                                            <Grid item={true} xs={12} md={12}>
                                                <Box display="flex" flex={1} justifyContent="center">
                                                    <Link sx={{width: {xs: "100%", lg: "70%"}}} underline="none" href={link} target="_blank">
                                                        <Button
                                                            onClick={trackDescriptionBtnClick}
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            sx={{
                                                                fontSize: 12,
                                                                color: "white",
                                                                textTransform: "none",
                                                                borderWidth: 1,
                                                                borderStyle: "solid",
                                                                borderColor: "rgba(255, 255, 255, 0.15)",
                                                                borderRadius: 0.25,
                                                                '&:hover': {
                                                                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                                                                    transition: "all 300ms ease-out"
                                                                }
                                                            }}>
                                                            {descriptionButtonText || "Description"}
                                                        </Button>
                                                    </Link>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                    <Box sx={{flexBasis: {xs: "40%", md: "30%", lg: "15%"}, display: {xs: "block", md: "block"}}}>
                        <Stack
                            sx={{height: "100%"}}
                            direction="column">
                            <Stack direction="row" justifyContent="center">
                                <Avatar
                                    variant="square"
                                    sx={{
                                        width: {xs: 50},
                                        height: {xs: 50},
                                        objectFit: "contain",
                                        mb: 1
                                    }}
                                    src={company.logo.asset.url}
                                    alt={`${role} role in geometry opportunities`}
                                    title={`${role} role in geometry`}
                                />
                            </Stack>
                            <Typography
                                variant="body2"
                                align="center"
                                sx={{color: "#ffffff", textTransform: "uppercase", fontSize: 10, letterSpacing: 1.05}}>
                                {company.name}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Opportunity;