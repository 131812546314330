import ReactDOM from "react-dom";
import React, { createContext, useContext, useState } from "react";

export const FullScreenContext = createContext(false);

export const FullScreenProvider = ({ children }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [buttonVisibility, setButtonVisibility] = useState(true);

  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);
  const setButtonVisible = (visible) => setButtonVisibility(!!visible);

  return (
    <FullScreenContext.Provider
      value={{
        isFullScreen,
        toggleFullScreen,
        buttonVisibility,
        setButtonVisible,
      }}
    >
      {children}
    </FullScreenContext.Provider>
  );
};

export const FullScreenToggle = ({ customBtn, hideInDesktop, containerClasses, mountOnBody }) => {
  const { isFullScreen, toggleFullScreen, buttonVisibility } =
    useContext(FullScreenContext);

  const element = (
    <>
      {buttonVisibility && (
        <>
          {!customBtn && (
            <span
              className={`fixed border-2 p-2 rounded-md bg-white z-[999] flex gap-2 text-base items-center cursor-pointer shadow-md hover:shadow-xl bottom-8 right-8 xl:bottom-16 xl:right-16 ${containerClasses}`}
              onClick={toggleFullScreen}
            >
              {isFullScreen && (
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="#000000"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="miter"
                  className="h-6 w-6 xl:h-4 xl:w-4"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <line x1="14" y1="10" x2="21" y2="3"></line>
                    <polyline points="20 10 14 10 14 4"></polyline>
                    <line x1="3" y1="21" x2="10" y2="14"></line>
                    <polyline points="4 14 10 14 10 20"></polyline>
                  </g>
                </svg>
              )}
              {!isFullScreen && (
                <svg
                  fill="#000000"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-6 xl:h-4 xl:w-4"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M2,21V11a1,1,0,0,1,2,0v7.586L18.586,4H11a1,1,0,0,1,0-2H21a1,1,0,0,1,1,1V13a1,1,0,0,1-2,0V5.414L5.414,20H13a1,1,0,0,1,0,2H3A1,1,0,0,1,2,21Z"></path>
                  </g>
                </svg>
              )}
              <span className="hidden lg:block">
                {isFullScreen ? "Collapse" : "Reader view"}
              </span>
            </span>
          )}
          {customBtn && <span onClick={toggleFullScreen}>{customBtn()}</span>}
        </>
      )}
    </>
  );

  return mountOnBody ? ReactDOM.createPortal(element, document.body) : element;
};
