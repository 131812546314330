import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import logoAnimation from "../animations/geometry-logo-animation.json";
import textAnimation from "../animations/geometry-text-in.json";
import hexagonUpAnimation from "../animations/hex-arrange-in-v1-up.json"
import hexagonDownAnimation from "../animations/hex-arrange-in-v2-down.json"
import { PopupButton } from "@typeform/embed-react"
import WhiteLogo from "../assets/images/white-logo plain.svg";
import { generateMetaSection } from "../helpers/meta.js";

export default function About({ callback, callback2, textOne, textTwo, metaTitle, metaDescription, metaKeywords }) {

    const logo = useRef();
    const text = useRef();

    const hexagonDown = useRef();
    const hexagonUp = useRef();

    useEffect(() => {
        callback(false);
        callback2(false);
        /* TODO: lottie 
        logo.current.goToAndStop(100, true);
        text.current.setDirection(-1);
        text.current.playSegments([50, 1], true);
        hexagonDown.current.playSegments([0, 50], true);
        hexagonUp.current.playSegments([0, 50], true);
        */
    }, []);

    return (
        <>
            {generateMetaSection("About")}
            <div className="relative w-full h-full overflow-hidden">
                <div className="fixed top-0 h-screen left-0 w-screen z-0">
                    <Lottie lottieRef={hexagonDown} className="absolute scale-50 lg:scale-100" style={{ left: "-300px", width: "600px", top: "-200px" }} animationData={hexagonDownAnimation} autoplay={false} loop={false} />
                    <Lottie lottieRef={hexagonUp} className="absolute scale-50 lg:scale-100" style={{ right: "-300px", width: "600px", bottom: "-300px" }} animationData={hexagonUpAnimation} autoplay={false} loop={false} />
                </div>
                <div className="absolute w-full h-full xl:-top-20 block lg:flex flex-row items-center z-10 no-scroll overflow-y-auto">
                    <div className="w-full lg:w-auto flex flex-row flex-nowrap items-center pr-0 lg:pr-20 mb-3 lg:mb-0">
                        { /* TODO: lottie */ false && (<div><Lottie lottieRef={logo} className="w-20 h-20 lg:w-32 lg:h-32 2xl:h-40 2xl:w-40 flex-none mr-4" animationData={logoAnimation} autoplay={false} loop={false} />
                            <Lottie lottieRef={text} className="h-full w-48 lg:w-60 2xl:w-72" animationData={textAnimation} autoplay={false} loop={false} /></div>)}
                        <img src={WhiteLogo}
                            className={`h-full w-48 lg:w-60 2xl:w-72`}
                            style={{ transformOrigin: "center left" }} alt="" />

                    </div>
                    <div className="pl-0 lg:pl-16 border-0 lg:border-l border-cyan grow w-full lg:w-auto flex-1 z-20 lg:pr-10 pb-6 lg:pb-0 fade-in">
                        <p className="text-white text-sm 2xl:text-lg xl:text-base font-light font-clarikageo mb-8 lg:mb-8">Geometry Research empowers protocols using cryptography. We ensure the security by performing audits and reviews, accelerate cryptographic primitives and critical pieces of software, enhance privacy across the stack using the methods and libraries suitable for each use case and innovate beyond the state of the art and build new applications.</p>
                        <p className="text-white text-sm 2xl:text-lg xl:text-base font-medium font-clarikageo mb-8 lg:mb-8">Our team has worked and collaborated with top protocols and teams, powering the security, scalability and privacy of protocols collectively measured in tens of billions of dollars.</p>
                        <p className="text-white text-sm 2xl:text-lg xl:text-base font-light font-clarikageo mb-8 lg:mb-8">
                            We will work together to:
                            <ul className="list-disc list-inside">
                                <li>Implement cutting-edge cryptographic protocols</li>
                                <li>Audit implementations and specifications</li>
                                <li>Extend protocol functionality</li>
                                <li>Collaborate on research papers</li>
                                <li>Help crypto applications to follow security best practices and accelerate performance</li>
                                <li>Mentor and support applied cryptography engineers</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}