import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-mathjax';
import rehypeRaw from 'rehype-raw';

const _mapProps = (props) => ({
  ...props,
  remarkPlugins: [remarkGfm, remarkMath],
  rehypePlugins: [(options)=>rehypeKatex({
    ...options,
    tex: {
        tags: 'ams'
      }
  })],
});

const Markdown = (props) => <ReactMarkdown {..._mapProps(props)} />;

export default Markdown;