import React, {useState, useEffect} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ReactGA from "react-ga4";
import sanityClient from "./client.js";
import Layout from "./components/Layout.js";
import Home from "./components/Home";
import About from "./components/About";
import NotebookPage from "./pages/NotebookPage.js";
import PortfolioPage from "./pages/PortfolioPage.js";
import TeamPage from "./pages/TeamPage.js";
import ContactPage from "./pages/ContactPage.js";
import NotebookMarkdownEntryPage from "./components/NotebookMarkdownEntryPage.js";
import PdfReadingPage from "./components/PDFReadingPage";
import { FullScreenProvider } from './components/FullScreenProvider';

import HiringPage from "./pages/HiringPage";

ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);

export default function App() {

    const [data, setData] = useState(null);
    const [light, setLight] = useState(false);
    const [landing, setLanding] = useState(false);

    useEffect(() => {
        sanityClient.fetch(`{
            "globals": *[_type == "globals"] {
                background {
                    asset-> {
                        _id,
                        url,
                    },
                },
                backgroundInner {
                    asset-> {
                        _id,
                        url,
                    },
                },
                home {
                    metaTitle,
                    metaDescription,
                    metaKeywords,
                },
                about {
                    textOne,
                    textTwo,
                    metaTitle,
                    metaDescription,
                    metaKeywords,
                },
                notebook {
                    title,
                    metaTitle,
                    metaDescription,
                    metaKeywords,
                },
                notebookPlaceholder {
                    asset-> {
                        _id,
                        url,
                    },
                },
                portfolio {
                    title,
                    metaTitle,
                    metaDescription,
                    metaKeywords,
                },
                team {
                    title,
                    metaTitle,
                    metaDescription,
                    metaKeywords,
                },
                contact {
                    title,
                    generalEmailAddress,
                    investmentsEmailAddress,
                    socials {
                        twitterHandle,
                        linkedinHandle,
                        githubHandle,
                    },
                    "locations": locations[] {
                        title,
                    },
                    metaTitle,
                    metaDescription,
                    metaKeywords,
                    copyright,
                }
            },          
            "categories": *[_type == "category"]|order(order asc) {
                title,
                slug,
                kind,
                "articles": *[_type == "article" && references(^._id) && defined(postDate) && dateTime(postDate) <= dateTime(now())] | order(postDate desc) {
                    title,
                    slug,
                    postDate,
                    image {
                        asset-> {
                            _id,
                            url,
                        },
                    },  
                    description,
                },
            },
            "articles": *[_type == "article" && defined(postDate) && dateTime(postDate) <= dateTime(now())] | order(postDate desc) {
                title,
                slug,
                postDate,
                category,
                image {
                    asset-> {
                        _id,
                        url,
                    },
                },  
                description,
            },
            
            "firms": *[_type == "firm"] {
                title,
                slug,
                icon {
                    asset-> {
                        _id,
                        url,
                    },
                },
                hoverIcon {
                    asset-> {
                        _id,
                        url,
                    },
                },
                logo {
                    asset-> {
                        _id,
                        url,
                    },
                },
                description,
                url,
                category,
            },
            
            "teamMembers": *[_type == "teamMember"] | order(priority desc) {
                title,
                slug,
                priority,
                image {
                    asset-> {
                        _id,
                        url,
                    },
                },
                position,
                description,
                socials {
                    twitterHandle,
                    linkedinHandle,
                    githubHandle,
                    websiteHandle,
                },
            },  
             "opportunities": *[_type == "opportunities"] | order(_createdAt desc){
                    company{
                        name, 
                        logo{asset->{url}}},
                    description,
                    descriptionButtonText,
                    location,
                    role,
                    team,
                    email,
                    backup,
                    link,
                    postedAt,
                    _createdAt
               }, 
               "mentors": *[_type == "mentors"]{
                    name,
                    bio,
                    role,
                    image{asset->{url}},
                    socials
                }, 
                "faqs": *[_type == "faqs"]{
                    question,
                    answer
                }, 
        }`).then((data) => setData(data)).catch(console.error);
    }, []);

    return (
        <FullScreenProvider>
            <BrowserRouter>
                <Layout callback={setLight} light={light} landing={landing}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Home
                                    callback={setLight}
                                    callback2={setLanding}
                                    metaTitle={data && data.globals[0].home.metaTitle}
                                    metaDescription={data && data.globals[0].home.metaDescription}
                                    metaKeywords={data && data.globals[0].home.metaKeywords}
                                />}/>
                        <Route
                            path="/about"
                            element={
                                <About
                                    callback={setLight}
                                    callback2={setLanding}
                                    textOne={data && data.globals[0].about.textOne}
                                    textTwo={data && data.globals[0].about.textTwo}
                                    metaTitle={data && data.globals[0].about.metaTitle}
                                    metaDescription={data && data.globals[0].about.metaDescription}
                                    metaKeywords={data && data.globals[0].about.metaKeywords}
                                />}/>
                        <Route
                            path="/notebook"
                            element={
                                <NotebookPage
                                    categories={data ? data.categories.filter(c => c.kind == "research") : []}
                                    callback={setLight}
                                    callback2={setLanding}
                                    articles={data ? data.articles : []}
                                    placeholderImage={data ? data.globals[0].notebookPlaceholder : null}
                                    metaTitle={data && data.globals[0].notebook.metaTitle}
                                    metaDescription={data && data.globals[0].notebook.metaDescription}
                                    metaKeywords={data && data.globals[0].notebook.metaKeywords}
                                />}/>
                        <Route
                            path="/articles"
                            element={
                                <NotebookPage
                                    categories={data ? data.categories.filter(c => c.kind == "article") : []}
                                    callback={setLight}
                                    callback2={setLanding}
                                    articles={data ? data.articles : []}
                                    placeholderImage={data ? data.globals[0].notebookPlaceholder : null}
                                    metaTitle={data && data.globals[0].notebook.metaTitle}
                                    metaDescription={data && data.globals[0].notebook.metaDescription}
                                    metaKeywords={data && data.globals[0].notebook.metaKeywords}
                                />}/>
 
                        <Route
                            path="/portfolio"
                            element={
                                <PortfolioPage
                                    callback={setLight}
                                    callback2={setLanding}
                                    firms={data ? data.firms : []}
                                    metaTitle={data && data.globals[0].portfolio.metaTitle}
                                    metaDescription={data && data.globals[0].portfolio.metaDescription}
                                    metaKeywords={data && data.globals[0].portfolio.metaKeywords}
                                />}/>
                        <Route
                            path="/team"
                            element={
                                <TeamPage
                                    callback={setLight}
                                    callback2={setLanding}
                                    teamMembers={data ? data.teamMembers : []}
                                    metaTitle={data && data.globals[0].team.metaTitle}
                                    metaDescription={data && data.globals[0].team.metaDescription}
                                    metaKeywords={data && data.globals[0].team.metaKeywords}
                                />}/>
                        <Route
                            path="/contact"
                            element={
                                <ContactPage
                                    callback={setLight}
                                    callback2={setLanding}
                                    generalEmailAddress={data ? data.globals[0].contact.generalEmailAddress : null}
                                    investmentsEmailAddress={data ? data.globals[0].contact.investmentsEmailAddress : null}
                                    locations={data ? data.globals[0].contact.locations : []}
                                    copyright={data ? data.globals[0].contact.copyright : null}
                                    socials={data ? data.globals[0].contact.socials : null}
                                    metaTitle={data && data.globals[0].contact.metaTitle}
                                    metaDescription={data && data.globals[0].contact.metaDescription}
                                    metaKeywords={data && data.globals[0].contact.metaKeywords}
                                />}
                        />
                        <Route
                            path="/hiring"
                            element={
                                <HiringPage
                                    opportunities={data ? data.opportunities : []}
                                    callback={setLight}
                                    callback2={setLanding}
                                />
                            }
                        />
                        <Route
                            path="/notebook/:slug"
                            element={
                                <NotebookMarkdownEntryPage
                                    callback={setLight}
                                    placeholderImage={data ? data.globals[0].notebookPlaceholder : null}/>}/>
                        <Route
                            path="/notebook-pdf/:slug"
                            element={
                                <PdfReadingPage
                                    callback={setLight}
                                    placeholderImage={data ? data.globals[0].notebookPlaceholder : null}/>}/>
                        <Route
                            path="/page/:slug"
                            element={
                                <NotebookMarkdownEntryPage
                                    callback={setLight}
                                    placeholderImage={data ? data.globals[0].notebookPlaceholder : null}
                                />}
                        />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </FullScreenProvider>
    );
}
