import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { truncateString } from "../helpers/truncateString.js";
import Lottie from "lottie-react";
import hexagonUpAnimation from "../animations/hex-arrange-in-v4-up.json"
import hexagonDownAnimation from "../animations/hex-arrange-in-v2-down.json"
import { generateMetaSection } from "../helpers/meta.js";

export default function PortfolioPage({ callback, callback2, firms, metaTitle, metaDescription, metaKeywords }) {
    
    const [infraFirms, setInfraFirms] = useState([]);
    const [supportFirms, setSupportFirms] = useState([]);
    const [grantFirms, setGrantFirms] = useState([]);

    const [currentFrim, setCurrentFirm] = useState(null);
    const [showMobile, setShowMobile] = useState(null);

    useEffect(()=>{
        if (infraFirms.length > 0){
            setCurrentFirm(infraFirms[0]);
        } else if (supportFirms.length > 0){
            setCurrentFirm(supportFirms[0]);
        } else if (grantFirms.length > 0) {
            setCurrentFirm(grantFirms[0]);
        }
    },[infraFirms, supportFirms, grantFirms]);

    const iconItem = (firm, current) => {

        const active = current && firm.slug.current === current.slug.current;

        return (
            <button
                key={firm.slug.current}
                className={`group w-28 mx-2 mb-4 xs:w-36 md:mx-2 xl:mx-2 2xl:w-28 2xl:mx-1 hover:opacity-100 transition-all duration-500 h-28 lg:h-20 flex flex-col my-2 xl:flex-col xl:h-24 justify-center items-center gap-2 lg:gap-4 relative ${active ? 'lg:opacity-100' : 'lg:opacity-40'}`}
                onClick={() => { setCurrentFirm(firm); setShowMobile(true) }}
            >
                <img
                    className={`group-hover:block hidden w-12 h-12 object-contain ${active ? 'group-hover:opacity-80' : 'group-hover:opacity-100'} transition-all duration-500`}
                    src={firm.hoverIcon.asset.url}
                    alt={firm.title}
                />
                <img
                    className={`group-hover:hidden block w-12 h-12 object-contain group-hover:opacity-100 transition-all duration-500`}
                    src={firm.icon.asset.url}
                    alt={firm.title}
                />
                <p className={`lg:block group-hover:opacity-100 text-white text-sm uppercase tracking-[4px] sm:tracking-[4px] lg:tracking-[4px] transition-all duration-500 group-hover:text-cyan transition-colors`}>{firm.title}</p>
            </button>
        );
    }

    const hexagonDown = useRef();
    const hexagonUp = useRef();

    useEffect(() => {
        callback(false);
        callback2(false);
        hexagonDown.current.playSegments([0, 50], true);
        hexagonUp.current.playSegments([0, 50], true);
    }, []);

    useEffect(() => {
      const infraFirms = [];
      const supportFirms = [];
      const grantFirms = [];

      firms.forEach(firm => {
        switch (firm.category) {
            case 'infra':
                infraFirms.push(firm);
                break;
            case 'grant':
                grantFirms.push(firm);
                break;
            default:
                supportFirms.push(firm);
                break;
        }
      });

      setInfraFirms(infraFirms);
      setSupportFirms(supportFirms);
      setGrantFirms(grantFirms);
    }, [firms])
    

    return (
        <>
            {generateMetaSection("Portfolio")}
            <div className="fixed top-0 h-screen left-0 w-screen z-0">
                <Lottie lottieRef={hexagonDown} className="absolute scale-50 lg:scale-100" style={{ left: "-300px", width: "600px", top: "-200px" }} animationData={hexagonDownAnimation} autoplay={false} loop={false} />
                <Lottie lottieRef={hexagonUp} className="absolute scale-50 lg:scale-100" style={{ right: "-200px", width: "700px", bottom: "-200px" }} animationData={hexagonUpAnimation} autoplay={false} loop={false} />
            </div>
            <div id="portfolio-page-container" className="absolute h-full lg:items-center w-full flex flex-col lg:flex-row font-clarikageo pb-3 fade-in">
                <div className={`lg:w-2/3 xl:w-2/5 py-8 flex-grow lg:flex-grow-0 lg:flex items-center lg:h-full lg:px-12 lg:border-l border-cyan border-opacity-25 ${showMobile ? 'flex' : 'hidden'}`}>
                    {currentFrim &&
                        <div className="text-white space-y-8">
                            <a className="mb-8" href={`${currentFrim.url}`}>
                                <img className="block lg:hidden mb-8 w-16" src={currentFrim.icon.asset.url} alt={currentFrim.title} />
                                <h2 className="font-bold text-xl xl:text-2xl">{currentFrim.title}</h2>
                            </a>
                            <p className="text-lg lg:text-lg">{currentFrim.description}</p>
                            <a className="text-cyan uppercase text-sm tracking-[7px] block py-3 hover:text-white transition-colors duration-500" href={`${currentFrim.url}`} target="_blank" rel="noreferrer noopener">Visit website</a>
                            <button type="button" className={`flex items-center group lg:hidden text-white text-sm tracking-[7px] uppercase z-50 cursor-pointer ${showMobile ? 'block' : 'hidden'} hover:text-cyan transition-colors duration-500`} onClick={() => { setShowMobile(false); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-compact-left text-white group-hover:text-cyan transition-all duration-500 inline-block mr-2" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z" />
                                </svg>
                                Back to portfolio
                            </button>
                        </div>
                    }
                </div>
                <div className={`lg:order-first index-0 w-full absolute lg:relative h-full max-h-full overflow-y-auto flex-grow lg:flex justify-center lg:pr-4 no-scroll ${showMobile ? 'hidden' : 'flex'}`}>
                    <div>
                        {infraFirms.length > 0 && <div>
                            <div className={`text-white text-center py-8 text-xl font-light px-12 xl:px-32`}>
                                Geometry is proud to support the following zero knowledge and infrustructure companies
                            </div>
                            <div className="flex flex-1 items-center justify-center flex-wrap w-full">
                                {infraFirms && infraFirms.map((firm) => iconItem(firm, currentFrim))}
                            </div>
                        </div>}
                        <div className={`grid grid-cols-1 ${supportFirms.length && grantFirms.length && 'xl:grid-cols-2'}`}>
                            {supportFirms.length > 0 && <div className='pb-12'>
                                <div className={`text-white text-center py-16 text-xl font-light px-4 xl:px-0 2xl:px-4 h-40`}>
                                    Geometry is also happy to support
                                </div>
                                <div className="flex items-center justify-center flex-wrap w-full lg:px-0">
                                    {supportFirms && supportFirms.map((firm) => iconItem(firm, currentFrim))}
                                </div>
                            </div>}
                            {grantFirms.length > 0 && <div className='pb-12'>
                                <div className={`text-white text-center py-16 text-xl font-light px-4 xl:px-0 2xl:px-4 h-40`}>
                                    Geometry is proud to support public-goods projects
                                </div>
                                <div className="flex items-center justify-center flex-wrap w-full lg:px-0">
                                    {grantFirms && grantFirms.map((firm) => iconItem(firm, currentFrim))}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}