import React from "react";
import "./Loader.css";

export default function Loader() {

    return (
        <div className="loader-wrapper">
            <div className="loader">
                <div>
                    <div>
                        <span className="hex-6"></span>
                        <span className="hex-3"></span>
                    </div>
                </div>
                <div>
                    <div>
                        <span className="hex-1"></span>
                        <span className="hex-4"></span>
                    </div>
                </div>
                <div>
                    <div>
                        <span className="hex-5"></span>
                        <span className="hex-2"></span>
                    </div>
                </div>
            </div>
        </div>
    )

}